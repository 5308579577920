import { render } from "@testing-library/react";
import React from "react";
import styles from "./sneakerCard.module.css";

export default function SneakerCard({ title, imgSrc, handleOnclick, id }) {
  return (
    <div className={styles.card} onClick={handleOnclick}>
      <img src={imgSrc} className={styles.sneakerImg} />
      <p>{title}</p>
    </div>
  );
}
