import { render } from "@testing-library/react";
import React from "react";
import styles from "./winnerSneaker.module.css";

export default function WinnerSneaker({ sneaker }) {
  if (sneaker != null) {
    return (
      <div className={styles.winner_container}>
        <p className={styles.winner_title}>A GYŐZTES:</p>
        <div className={styles.card}>
          <img src={sneaker.imgSrc} className={styles.sneakerImg} />
          <p>{sneaker.title}</p>
        </div>
        <p className="cta-text">
          Vedd meg most most 20% kedvezménnyel a newbalance.hu-n!
        </p>
      </div>
    );
  } else {
    return "OK";
  }
}
