const Sneakers = [
  {
    id: 1,
    title: "Suede Classic XXL",
    imgSrc: "../sneakers/1.jpg",
    url:
      "https://www.ecipo.hu/sportcipo-puma-suede-classic-xxl-374915-02-high-risk-red-puma-white.html",
  },
  {
    id: 2,
    title: "BIG STAR",
    imgSrc: "../sneakers/2.jpg",
    url: "https://www.ecipo.hu/tornacipo-big-star-ee274303-black.html",
  },
  {
    id: 3,
    title: "W520LP7",
    imgSrc: "../sneakers/3.jpg",
    url: "https://www.ecipo.hu/cipo-new-balance-w520lp7-szurke.html",
  },
  {
    id: 4,
    title: "Bmw Mms Wired Cage",
    imgSrc: "../sneakers/4.jpg",
    url:
      "https://www.ecipo.hu/sportcipo-puma-bmw-mms-wired-cage-306504-01-p-black-p-black-p-white.html",
  },
  {
    id: 5,
    title: "Virtus NA4ERY Blu Marine 176",
    imgSrc: "../sneakers/5.jpg",
    url:
      "https://www.ecipo.hu/sportcipo-napapijri-virtus-na4ery-blu-marine-176.html",
  },
  {
    id: 6,
    title: "Versablast",
    imgSrc: "../sneakers/6.jpg",
    url:
      "https://www.ecipo.hu/cipo-asics-versablast-1012a835-french-blue-champagne-400.html",
  },
  {
    id: 7,
    title: "WP40-9284Y",
    imgSrc: "../sneakers/7.jpg",
    url: "https://www.ecipo.hu/sportcipo-sprandi-wp40-9284y-pink.html",
  },
  {
    id: 8,
    title: "Tesha FL5TES ELE12",
    imgSrc: "../sneakers/8.jpg",
    url: "https://www.ecipo.hu/sportcipo-guess-tesha-fl5tes-ele12-pink.html",
  },
  {
    id: 9,
    title: "Ua Jet 3022051-403",
    imgSrc: "../sneakers/9.jpg",
    url: "https://www.ecipo.hu/cipo-under-armour-ua-jet-3022051-403-blu.html",
  },
  {
    id: 10,
    title: "Tarrenz Sb 370551",
    imgSrc: "../sneakers/10.jpg",
    url:
      "https://www.ecipo.hu/sportcipo-puma-tarrenz-sb-370551-02-taffy-puma-black.html",
  },
  {
    id: 11,
    title: "Hansin FL5HNS FAL12",
    imgSrc: "../sneakers/11.jpg",
    url: "https://www.ecipo.hu/sportcipo-guess-hansin-fl5hns-fal12-blkbr.html",
  },
  {
    id: 12,
    title: "Venture Runner CK2944 400",
    imgSrc: "../sneakers/12.jpg",
    url:
      "https://www.ecipo.hu/cipo-nike-venture-runner-ck2944-400-midnight-navy-white.html",
  },
  {
    id: 13,
    title: "Resolve 194739",
    imgSrc: "../sneakers/13.jpg",
    url:
      "https://www.ecipo.hu/sportcipo-puma-resolve-194739-03-high-risk-red-puma-black.html",
  },
  {
    id: 14,
    title: "Venture Runner CK2944",
    imgSrc: "../sneakers/14.jpg",
    url:
      "https://www.ecipo.hu/cipo-nike-venture-runner-ck2944-005-photon-dust-game-royal-white.html",
  },
  {
    id: 15,
    title: "X8X066 XK199 N440",
    imgSrc: "../sneakers/15.jpg",
    url:
      "https://www.ecipo.hu/sportcipo-ea7-emporio-armani-x8x066-xk199-n440-navy-silver-honey.html",
  },
  {
    id: 16,
    title: "R78 373117",
    imgSrc: "../sneakers/16.jpg",
    url:
      "https://www.ecipo.hu/sportcipo-puma-r78-373117-26-black-white-puma-new-navy.html",
  },
];

export default Sneakers;
