import React, { useState, useEffect } from "react";

import "./App.css";
import logo from "./imgres/logo.png";
import SneakerCard from "./SneakerCard";
import WinnerSneaker from "./WinnerSneaker";
import Sneakers from "./sneakers";

function App() {
  const [actualBacklog, setActualBacklog] = useState(Sneakers); //storing the remaining winner sneakers
  const [nextRoundBacklog, setNextRoundBacklog] = useState([]); //winner sneakers go here
  const [actualShowroom, setActualShowroom] = useState([]); //storing the currently showed sneakers
  const [winner, setWinner] = useState(null);
  const [roundCounter, setRoundCounter] = useState(1);

  function getCardItems(items) {
    return items.map((sneaker) => (
      <SneakerCard
        key={sneaker.id}
        id={sneaker.id}
        title={sneaker.title}
        imgSrc={sneaker.imgSrc}
        handleOnclick={handleOnclick.bind(this, sneaker)}
      />
    ));
  }

  function shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  function handleOnclick(sneaker) {
    console.log("WINNER:", sneaker.id);
    setRoundCounter(roundCounter + 1);
    setNextRoundBacklog([...nextRoundBacklog, sneaker]);
  }

  useEffect(() => {
    setNextRound();
  }, [nextRoundBacklog]);

  function setNextRound() {
    setActualShowroom([]);

    if (actualBacklog.length < 2) {
      console.log("------------ ROUND ENDS ------------");
      if (nextRoundBacklog.length == 1) {
        console.log("------------ GAME ENDS ------------");
        setWinner(nextRoundBacklog[0]);
      } else {
        setActualBacklog(nextRoundBacklog);
        setNextRoundBacklog([]);
      }
    } else {
      console.log(actualBacklog[0].id, "vs", actualBacklog[1].id);
      setActualBacklog(shuffle(actualBacklog));
      setActualShowroom(Array(actualBacklog[0], actualBacklog[1]));
      setActualBacklog(actualBacklog.slice(2));
    }
  }

  if (winner === null && actualShowroom.length > 1) {
    let items = getCardItems(actualShowroom);
    return (
      <div className="App">
        <img id="logo" src={logo} />
        <p className="instruction-text">Melyik tetszik jobban?</p>
        <p className="round-counter">Forduló: {roundCounter}/15</p>
        <div className="sneaker-container">{items}</div>
      </div>
    );
  } else {
    //show winner
    return (
      <div className="App">
        <img id="logo" src={logo} />
        <div className="sneaker-container">
          <WinnerSneaker sneaker={winner} />
        </div>
      </div>
    );
  }
}

export default App;
